define("discourse/plugins/discourse-talk-section/discourse/components/media-player", ["exports", "@ember/component", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _component, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{{model}}}
  
  */
  {
    "id": "FHN5wIsA",
    "block": "[[[41,[28,[32,0],[\"model\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"model\"],[[28,[32,1],[\"model\"],null]]]]],[[[1,[52,[30,1,[\"model\"]],[28,[30,1,[\"model\"]],null,null],[28,[32,2],[[30,0],\"model\",\"[\\\"The `model` property path was used in the `discourse/plugins/discourse-talk-section/discourse/components/media-player.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[1,\"\\n\"]],[\"maybeHelpers\"],false,[\"if\",\"model\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-talk-section/discourse/components/media-player.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    didInsertElement() {
      // YouTube player
      var lazyYT = $(this.element).find(".lazyYT");
      if (lazyYT.length !== 0) {
        lazyYT.lazyYT();
        lazyYT.attr("data-height", 200);
        lazyYT.attr("data-width", 360);
        lazyYT.css("padding-bottom", "200px");
        lazyYT.css("width", "360px");
      }

      // Soundcloud player
      if ($(this.element).find(".sc-player").size()) {
        $.scPlayer({}, $(this.element).find(".sc-player"));
      }

      // Spotify player
      if ($(this.element).find(".spotify-player").size()) {
        var playerElem = $(this.element).find(".spotify-player");
        var link = playerElem.attr('href');
        var url = 'https://open.spotify.com/embed?uri=spotify:user:spotify:';
        if (link.includes('open.spotify.com')) {
          let startOfMediaCode = link.indexOf('open.spotify.com') + 17;
          let endOfMediaCode = link[link.length - 1] == '/' ? link.length - 2 : link.length - 1;
          url += link.substring(startOfMediaCode, endOfMediaCode).replace('/', ':');
        } else if (link.includes('spotify:')) {
          url += link.replace('spotify:', '');
        }
        playerElem.replaceWith('<iframe src="' + url + '" width="360" height="80" frameborder="0" allowtransparency="true"></iframe>');
      }
    }
  }));
});