define("discourse/plugins/discourse-talk-section/discourse/controllers/talk-edit", ["exports", "discourse-common/utils/decorators", "@ember/object", "@ember/controller"], function (_exports, _decorators, _object, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    // By default we haven't saved anything
    saved: false,
    saveButtonText(isSaving) {
      return isSaving ? I18n.t('saving') : I18n.t('save');
    },
    setSliderPosition() {
      var className = this.get('model').get('slider_text_position');
      $('#' + className).prop('checked', true);
    },
    titleValidation(title, lastValidatedAt) {
      if (this.model.get('titleMissing') && !title) {
        return _object.default.create({
          failed: true,
          reason: I18n.t('talk.article.title') + " " + I18n.t('talk.missing'),
          lastShownAt: lastValidatedAt
        });
      }
    },
    contentValidation(content, lastValidatedAt) {
      if (this.model.get('contentMissing') && !content) {
        return _object.default.create({
          failed: true,
          reason: I18n.t('talk.article.body') + " " + I18n.t('talk.missing'),
          lastShownAt: lastValidatedAt
        });
      }
    },
    publishedAtValidation(published_at, lastValidatedAt) {
      if (this.model.get('publishedAtMissing') && !published_at) {
        return _object.default.create({
          failed: true,
          reason: I18n.t('talk.article.published_at') + " " + I18n.t('talk.missing'),
          lastShownAt: lastValidatedAt
        });
      }
    },
    imageFileNameValidation(image_file_name, lastValidatedAt) {
      if (this.model.get('imageFileNameMissing') && !image_file_name) {
        return _object.default.create({
          failed: true,
          reason: I18n.t('talk.article.image_file_name.title') + " " + I18n.t('talk.missing'),
          lastShownAt: lastValidatedAt
        });
      }
    },
    croppedImageFileNameValidation(cropped_image_file_name, lastValidatedAt) {
      if (this.model.get('croppedImageFileNameMissing') && !cropped_image_file_name) {
        return _object.default.create({
          failed: true,
          reason: I18n.t('talk.article.cropped_image_file_name.title') + " " + I18n.t('talk.missing'),
          lastShownAt: lastValidatedAt
        });
      }
    },
    timezoneOffset() {
      var date = new Date().toString();
      return date.substring(date.indexOf("GMT") + 3, date.indexOf("GMT") + 6);
    },
    validateAndSave() {
      var save = true;
      if (!this.model.get('title')) {
        this.model.set('titleMissing', true);
        this.notifyPropertyChange('model.title');
        save = false;
      }
      if (!this.model.get('content')) {
        this.model.set('contentMissing', true);
        this.notifyPropertyChange('model.reply');
        save = false;
      }
      if (!this.model.get('published_at')) {
        this.model.set('publishedAtMissing', true);
        this.notifyPropertyChange('model.published_at');
        save = false;
      }
      if (!this.model.get('image_file_name')) {
        this.model.set('imageFileNameMissing', true);
        this.notifyPropertyChange('model.image_file_name');
        save = false;
      }
      if (!this.model.get('cropped_image_file_name')) {
        this.model.set('croppedImageFileNameMissing', true);
        this.notifyPropertyChange('model.cropped_image_file_name');
        save = false;
      }
      if (save) {
        // Trigger the route's save action
        this.send('updateArticle');
      }
      return save;
    },
    choosePosition(pos) {
      var model = this.model;
      model.set('slider_text_position', pos);
    }
  }, [["method", "saveButtonText", [(0, _decorators.default)("model.isSaving")]], ["method", "titleValidation", [(0, _decorators.default)('model.title', 'lastValidatedAt', 'model.titleMissing')]], ["method", "contentValidation", [(0, _decorators.default)('model.content', 'lastValidatedAt', 'model.contentMissing')]], ["method", "publishedAtValidation", [(0, _decorators.default)('model.published_at', 'lastValidatedAt', 'model.publishedAtMissing')]], ["method", "imageFileNameValidation", [(0, _decorators.default)('model.image_file_name', 'lastValidatedAt', 'model.imageFileNameMissing')]], ["method", "croppedImageFileNameValidation", [(0, _decorators.default)('model.cropped_image_file_name', 'lastValidatedAt', 'model.croppedImageFileNameMissing')]], ["method", "timezoneOffset", [(0, _decorators.default)()]], ["method", "validateAndSave", [_object.action]], ["method", "choosePosition", [_object.action]]]));
});