define("discourse/plugins/discourse-talk-section/discourse/routes/talk-index", ["exports", "discourse/plugins/discourse-talk-section/discourse/models/article", "discourse/plugins/discourse-talk-section/discourse/models/articles", "discourse/models/user", "discourse/routes/discourse", "discourse-common/lib/get-owner"], function (_exports, _article, _articles, _user, _discourse, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    init: function () {
      var self = this;
      _articles.default.reopen({
        didGetMore: function () {
          if (self.controller) {
            self.controller.send('gotMore');
          }
        }
      });
    },
    setupController: function (controller, model) {
      controller.set('model', model);
      controller.send('gotMore');
      this._super(controller, model);
    },
    model(params, transition) {
      var model = _articles.default.create();
      return this.events.fetchData(model);
    },
    events: {
      fetchData(model) {
        return $.ajax({
          url: "/talk",
          type: "GET",
          data: {
            offset: model.get('offset'),
            limit: model.get('limit')
          }
        }).then(function (result) {
          var index = 0;
          var tempArticles = [];
          result.articles.forEach(function (article) {
            var articleModel = _article.default.create(article);
            if (_user.default.current()) {
              articleModel.set('canEdit', articleModel.user_id == _user.default.current().id || _user.default.current().admin);
              articleModel.set('canDelete', articleModel.user_id == _user.default.current().id || _user.default.current().admin);
            }
            if (articleModel.featured && index % 2) {
              tempArticles.addObject(articleModel);
            } else {
              model.articles.addObject(articleModel);
              if (articleModel.featured) {
                index = 0;
              } else {
                index++;
              }
              if (tempArticles.length > 0) {
                model.articles.addObjects(tempArticles);
                tempArticles = [];
                index = 0;
              }
            }
          });
          model.articles.addObjects(tempArticles);
          result.featured.forEach(function (article) {
            var articleModel = _article.default.create(article);
            var className = articleModel.get('slider_text_position').replace('_', '-');
            articleModel.set('className', className);
            if (articleModel.get('quote')) {
              articleModel.set('hasQuote', true);
            }
            model.featured.addObject(articleModel);
          });
          model.randomizeFeatured();
          model.set('offset', result.offset);
          model.set('limit', result.limit);
          model.didGetMore();
          return model;
        });
      }
    },
    actions: {
      editPost(post) {
        const router = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:router");
        router.transitionTo("talk.edit", post.id);
      },
      deletePost(post) {
        var model = this.controller.model;
        $.when($.ajax({
          url: "/talk/" + post.id,
          type: "DELETE"
        })).then(function (result) {
          if (result.success) {
            for (var i = 0; i < model.articles.length; i++) {
              if (post.id == model.articles[i].id) {
                model.articles[i].set('deleted', true);
                setTimeout(function () {
                  $(".talk .articles .talk-post.deleted").height('0');
                }, 100);
                break;
              }
            }
          }
        });
      },
      getMore() {
        var limit = this.controller.model.get('limit'),
          offset = this.controller.model.get('offset');
        this.controller.model.set('offset', offset + limit);
        this.events.fetchData(this.controller.model);
      }
    }
  });
});