define("discourse/plugins/discourse-talk-section/discourse/models/article", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Article extends _object.default {
    isSaving = false;
    constructor() {
      super(...arguments);
      this.isSaving = false;
      if (this.published_at) {
        const date = new Date(this.published_at.replace(" ", "T"));
        const mins = String(date.getMinutes()).padStart(2, "0");
        const hrs = String(date.getHours()).padStart(2, "0");
        this.published_at_time = `${hrs}:${mins}`;
      }
    }
    get shareUrl() {
      if (this.id) {
        return `/news/${this.id}`;
      }
      return null;
    }
    get isNews() {
      return this.type === 'news';
    }
    static #_ = (() => dt7948.n(this.prototype, "isNews", [(0, _object.computed)('type')]))();
    get isTalk() {
      return this.type === 'talk';
    }
    static #_2 = (() => dt7948.n(this.prototype, "isTalk", [(0, _object.computed)('type')]))();
  }
  _exports.default = Article;
});