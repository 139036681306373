define("discourse/plugins/discourse-talk-section/discourse/templates/talk/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='talk'>
  
    {{#if this.currentUser.admin}}
      <LinkTo @route="talk.new" class="btn btn-primary new-talk-btn">
        {{i18n 'talk.new_article'}}
      </LinkTo>
    {{/if}}
  
    {{#if this.siteSettings.talk_header_slider_enabled}}
      <div class="slider">
        {{#each this.model.featured as |post index|}}
          <div class="slide" data-slider='1'>
            <LinkTo @route="talk.article" @model="{{post.id}}">
              <div class="slide-content">
                <img src= "{{post.image_file_name}}" alt="" />
                <div class="slide-text {{post.className}}">
                  <h1>{{post.title}}</h1>
                  {{#if post.hasQuote}}
                    <h2>&ldquo;{{{post.quote}}}&rdquo;</h2>
                  {{/if}}
                </div>
              </div>
            </LinkTo>
          </div>
        {{/each}}
      </div>
    {{/if}}
  
    {{component 'talk-feed' model=this.model loadingMore=this.loadingMore getMore=(action 'getMore') gotMore=(action 'gotMore') edit=(action 'editPost') delete=(action 'deletePost')}}
  
  </div>
  
  */
  {
    "id": "5ijuS63r",
    "block": "[[[10,0],[14,0,\"talk\"],[12],[1,\"\\n\\n\"],[41,[30,0,[\"currentUser\",\"admin\"]],[[[1,\"    \"],[8,[39,2],[[24,0,\"btn btn-primary new-talk-btn\"]],[[\"@route\"],[\"talk.new\"]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,3],[\"talk.new_article\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"talk_header_slider_enabled\"]],[[[1,\"    \"],[10,0],[14,0,\"slider\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"model\",\"featured\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"slide\"],[14,\"data-slider\",\"1\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"talk.article\",[29,[[30,1,[\"id\"]]]]]],[[\"default\"],[[[[1,\"\\n            \"],[10,0],[14,0,\"slide-content\"],[12],[1,\"\\n              \"],[10,\"img\"],[15,\"src\",[29,[[30,1,[\"image_file_name\"]]]]],[14,\"alt\",\"\"],[12],[13],[1,\"\\n              \"],[10,0],[15,0,[29,[\"slide-text \",[30,1,[\"className\"]]]]],[12],[1,\"\\n                \"],[10,\"h1\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"hasQuote\"]],[[[1,\"                  \"],[10,\"h2\"],[12],[1,\"“\"],[2,[30,1,[\"quote\"]]],[1,\"”\"],[13],[1,\"\\n\"]],[]],null],[1,\"              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[46,\"talk-feed\",null,[[\"model\",\"loadingMore\",\"getMore\",\"gotMore\",\"edit\",\"delete\"],[[30,0,[\"model\"]],[30,0,[\"loadingMore\"]],[28,[37,10],[[30,0],\"getMore\"],null],[28,[37,10],[[30,0],\"gotMore\"],null],[28,[37,10],[[30,0],\"editPost\"],null],[28,[37,10],[[30,0],\"deletePost\"],null]]],null],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[\"post\",\"index\"],false,[\"div\",\"if\",\"link-to\",\"i18n\",\"each\",\"-track-array\",\"img\",\"h1\",\"h2\",\"component\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-talk-section/discourse/templates/talk/index.hbs",
    "isStrictMode": false
  });
});