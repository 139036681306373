define("discourse/plugins/discourse-talk-section/discourse/controllers/talk-index", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    loadingMore: true,
    actions: {
      editPost(post) {
        this.get('target').send('editPost', post);
      },
      deletePost(post) {
        this.get('target').send('deletePost', post);
      },
      getMore: function () {
        if (this.get('loadingMore')) {
          return;
        }
        this.set('loadingMore', true);
        this.get('target').send('getMore');
      },
      gotMore: function () {
        this.set('loadingMore', false);
      }
    }
  });
});