define("discourse/plugins/discourse-talk-section/discourse/talk-section-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route('talk', {
      path: '/talk'
    }, function () {
      this.route('new');
      // this.route('article', {path: '/view/:view_article_id'});
      this.route('article', {
        path: '/:article_id'
      });
      this.route('edit', {
        path: '/edit/:edit_article_id'
      });
    });

    // resource: 'talk',
    // path: '/talk',
    // map() {
    //   this.route('index', {path: '/'});
    // }
  }
});