define("discourse/plugins/discourse-talk-section/discourse/models/articles", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend({
    articles: [],
    featured: [],
    offset: 0,
    limit: 20,
    init: function () {
      this.set('articles', []);
      this.set('featured', []);
      this.set('offset', 0);
      this.set('limit', 20);
    },
    didGetMore: function () {
      console.log("Did get more!");
    },
    randomizeFeatured: function () {
      var array = this.featured;
      var currentIndex = array.length,
        temporaryValue,
        randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      this.set('featured', array);
    }
  });
});