define("discourse/plugins/discourse-talk-section/discourse/routes/talk-new", ["exports", "discourse/plugins/discourse-talk-section/discourse/models/article", "discourse/models/user", "discourse/routes/discourse", "discourse-common/lib/get-owner", "@ember/runloop"], function (_exports, _article, _user, _discourse, _getOwner, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    beforeModel(transition) {
      if (!_user.default.current() || !_user.default.current().get('admin')) {
        const router = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:router");
        router.transitionTo("talk.index");
      }
    },
    setupController: function (controller, model) {
      model = _article.default.create();
      controller.set("model", model);
    },
    actions: {
      saveArticle() {
        var self = this;
        var model = this.controller.model;
        var date = new Date(model.published_at);
        if (model.published_at_time) {
          date = new Date(model.published_at + " " + model.published_at_time);
        }
        model.set('published_at', date.toJSON());
        model.set('isSaving', true);
        $.ajax({
          url: "/talk/new",
          type: "POST",
          data: {
            content: model.content,
            disco1_file_name: model.disco1_file_name,
            disco1_info: model.disco1_info,
            disco1_name: model.disco1_name,
            disco2_file_name: model.disco2_file_name,
            disco2_info: model.disco2_info,
            disco2_name: model.disco2_name,
            disco3_file_name: model.disco3_file_name,
            disco3_info: model.disco3_info,
            disco3_name: model.disco3_name,
            facebook_link: model.facebook_link,
            image_file_name: model.image_file_name,
            cropped_image_file_name: model.cropped_image_file_name,
            ingress: model.ingress,
            quote: model.quote,
            slider_text_position: model.slider_text_position,
            link: model.link,
            media_link1: model.media_link1,
            media_link2: model.media_link2,
            media_link3: model.media_link3,
            published_at: model.published_at,
            subject_user_id: model.subject_user_id,
            title: model.title,
            twitter_link: model.twitter_link,
            visible: model.visible,
            featured: model.featured,
            user_id: _user.default.current().id
          }
        }).then(function (result) {
          self.controller.set('saved', true);
          model.set('isSaving', false);
          const router = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:router");
          router.transitionTo("talk.index");
        });
      }
    }
  });
});