define("discourse/plugins/discourse-talk-section/lib/slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Slider {
    constructor(slideset) {
      this.slideset = slideset; // Array of slide elements
      this.waitDelay = 5000; // Delay between slide changes
      this.activeSlide = 0; // Current active slide index
      this.numSlides = slideset.length; // Total slides
      this.timeouts = [];
      this.buttons = [];
      this.buttonContainer = null;
      this.slideButtonClass = 'fa-circle-thin';
      this.activeSlideButtonClass = 'fa-circle';
      this.setupNavigation();
      this.scheduleNextChange();

      // Event listeners for hovering
      slideset.forEach(slide => {
        slide.addEventListener("mouseenter", () => this.cancelTimeouts());
        slide.addEventListener("mouseleave", () => this.scheduleNextChange());
      });
    }
    setupNavigation() {
      this.buttonContainer = document.createElement("div");
      this.buttonContainer.className = "slider-nav-buttons";
      this.slideset.forEach((slide, index) => {
        const button = document.createElement("i");
        button.className = `fa ${this.slideButtonClass}`;
        button.addEventListener("click", () => this.goToSlide(index));
        this.buttons.push(button);
        this.buttonContainer.appendChild(button);
      });

      // Append navigation to the slider container
      this.slideset[this.slideset.length - 1].parentNode.appendChild(this.buttonContainer);
      this.updateNavButtons();
    }
    updateNavButtons() {
      this.buttons.forEach((button, index) => {
        button.classList.remove(this.slideButtonClass, this.activeSlideButtonClass);
        if (index === this.activeSlide) {
          const activeColor = window.getComputedStyle(this.slideset[this.activeSlide]).color;
          this.buttonContainer.style.color = activeColor; // Set button color to active slide color
          button.classList.add(this.activeSlideButtonClass);
        } else {
          button.classList.add(this.slideButtonClass);
        }
      });
    }
    getPosition(nextSlide) {
      if (nextSlide === "next") {
        nextSlide = this.activeSlide + 1;
      }
      if (nextSlide >= this.numSlides) {
        this.activeSlide = 0;
      } else {
        this.activeSlide = nextSlide;
      }
      return -100 * this.activeSlide;
    }
    goToSlide(slideIndex) {
      const position = this.getPosition(slideIndex);
      this.slideset.forEach(slide => {
        slide.style.transform = `translate3d(${position}%, 0, 0)`;
      });
      this.updateNavButtons();
    }
    showNextSlide() {
      this.goToSlide("next");
      this.scheduleNextChange();
    }
    cancelTimeouts() {
      this.timeouts.forEach(timeout => clearTimeout(timeout));
      this.timeouts = [];
    }
    scheduleNextChange() {
      this.cancelTimeouts();
      this.timeouts.push(setTimeout(() => this.showNextSlide(), this.waitDelay));
    }
  }
  class Sliders {
    constructor() {
      this.sliders = [];
      const sliders = document.querySelectorAll('[data-slider]');
      for (let i = 1; i <= 10; i++) {
        const slideset = Array.from(document.querySelectorAll(`[data-slider="${i}"]`));
        if (slideset.length > 0) {
          this.sliders.push(new Slider(slideset));
        }
      }
    }
  }
  _exports.default = Sliders;
});