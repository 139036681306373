define("discourse/plugins/discourse-talk-section/discourse/routes/talk-article", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-talk-section/discourse/models/article"], function (_exports, _discourse, _article) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    setupController: function (controller, model) {
      this._super(controller, model);
    },
    model(params, transition) {
      return $.ajax({
        url: "/talk/" + params.article_id,
        type: "GET"
      }).then(function (result) {
        var postModel = _article.default.create(result.talk_article);
        return postModel;
      });
    }
  });
});