define("discourse/plugins/discourse-talk-section/discourse/components/talk-feed", ["exports", "@ember/component", "discourse/mixins/scrolling", "discourse/plugins/discourse-talk-section/lib/slider", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _scrolling, _slider, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="talk-feed">
  
    <div class="articles">
  
      {{#each this.model.articles as |post index|}}
        {{#if post.featured}}
          {{component 'talk-post' post=post edit=(action 'editPost') delete=(action 'deletePost') class='featured'}}
        {{/if}}
        {{#unless post.featured}}
          {{component 'talk-post' post=post edit=(action 'editPost') delete=(action 'deletePost')}}
        {{/unless}}
      {{/each}}
  
    </div>
  
    <div class="spinner-container">
      {{conditional-loading-spinner condition=this.loadingMore}}
    </div>
  
  </div>
  
  */
  {
    "id": "7+rb5zSl",
    "block": "[[[10,0],[14,0,\"talk-feed\"],[12],[1,\"\\n\\n  \"],[10,0],[14,0,\"articles\"],[12],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"model\",\"articles\"]]],null]],null],null,[[[41,[30,1,[\"featured\"]],[[[1,\"        \"],[46,\"talk-post\",null,[[\"post\",\"edit\",\"delete\",\"class\"],[[30,1],[28,[37,5],[[30,0],\"editPost\"],null],[28,[37,5],[[30,0],\"deletePost\"],null],\"featured\"]],null],[1,\"\\n\"]],[]],null],[41,[51,[30,1,[\"featured\"]]],[[[1,\"        \"],[46,\"talk-post\",null,[[\"post\",\"edit\",\"delete\"],[[30,1],[28,[37,5],[[30,0],\"editPost\"],null],[28,[37,5],[[30,0],\"deletePost\"],null]]],null],[1,\"\\n\"]],[]],null]],[1,2]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"spinner-container\"],[12],[1,\"\\n    \"],[1,[28,[35,7],null,[[\"condition\"],[[30,0,[\"loadingMore\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[\"post\",\"index\"],false,[\"div\",\"each\",\"-track-array\",\"if\",\"component\",\"action\",\"unless\",\"conditional-loading-spinner\"]]",
    "moduleName": "discourse/plugins/discourse-talk-section/discourse/components/talk-feed.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(_scrolling.default, {
    didInsertElement() {
      this._readjustScrollPosition();
      this.bindScrolling();
      window.sliders = new _slider.default();
    },
    willDestroyElement() {
      $(window).scrollTop(0);
      this.unbindScrolling();
    },
    _readjustScrollPosition() {
      const scrollTo = this.session.get('talkListScrollPosition');
      if (scrollTo && scrollTo >= 0) {
        if ($(window).height() < scrollTo) {
          this.getMore();
        }
        (0, _runloop.schedule)('afterRender', () => $(window).scrollTop(scrollTo + 1));
      } else {
        //Ember.run.scheduleOnce('afterRender', this, this.loadMoreUnlessFull);
      }
    },
    saveScrollPosition() {
      this.session.set('talkListScrollPosition', $(window).scrollTop());
    },
    scrolled() {
      this.saveScrollPosition();
      if (this.isScrolledToBottom()) {
        this.getMore();
      }
    },
    isScrolledToBottom() {
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 50 && $(document).height() > $(window).height()) {
        return true;
      }
      return false;
    },
    actions: {
      editPost(post) {
        this.edit(post);
      },
      deletePost(post) {
        this.delete(post);
      }
    }
  }));
});